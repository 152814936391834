<template>
    <div class="sub-head">
        <div class="sub-visual">
            <v-container style="overflow: hidden">
                <div class="sub-visual__title" data-aos="fade-right">
                    <div>
                        <h2 class="tit tit--xl line-height-12 pointed-title">
                            {{ sh }}
                        </h2>
                        <p class="mt-12 mt-md-16 page-text page-text--lg">금산군에서 나의 자리를 찾다. 금산군 농촌신활력플러스사업을 소개합니다.</p>
                    </div>
                </div>
            </v-container>
            <v-img :src="`${image}`" class="d-md-none" />
            <div :style="`background-image: url(${image});`" class="sub-visual__image d-none d-md-block" />
        </div>
        <client-gnb className="sub-tab" :tabActive="tabActive" :sh="sh" id="sub-tab" />
    </div>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";

export default {
    props: {
        sh: { type: String, default: "" },
        tabActive: { type: String, default: "" },
        image: { type: String, default: null },
    },
    components: {
        ClientGnb,
    },
    data: () => {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>

<style lang="scss" scoped>
/* sub-visual */
.sub-visual {
    &__title {
        padding: 30px 0 30px;
        display: flex;
        align-items: center;
    }
    .pointed-title {
        &::after {
            right: -26px;
        }
    }
}
@media (min-width: 768px) {
    .sub-visual {
        &__image {
            height: 260px;
            background-size: 100%;
            background-position: center top;
            background-repeat: no-repeat;
        }
    }
}
@media (min-width: 1200px) {
    .sub-visual {
        &__image {
            background-size: cover;
            background-attachment: fixed;
        }
    }
}
@media (min-width: 1464px) {
    .sub-visual {
        &__title {
            padding: 100px 0 60px;
            height: 270px;
        }
        .pointed-title {
            &::after {
                right: -36px;
            }
        }
    }
}
</style>