var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-primary"
  }, [_c('div', {
    staticClass: "input-primary__tit"
  }, [_c('p', {
    staticClass: "page-text--sm"
  }, [_vm._v(_vm._s(_vm.tit))])]), _c('div', {
    staticClass: "input-primary__input"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }