<template>
    <client-page class="wrap--sub">
        <sub-visual visual="제조지원·교육"></sub-visual>
        <service-navigation-row />

        <section class="section">
            <v-container>
                <service-program-tit/>
                
                <service-program-card v-bind="{ program }" />

                <v-divider class="border-2 grey-13 mt-40 mt-lg-60" />

                <div class="pa-20 pa-md-30 pa-lg-40">
                    <div class="page-text" v-html="program?.detail" />
                </div>

                <v-divider />

                <div class="board-buttons d-flex justify-end mt-20 mt-lg-30">
                    <v-btn @click="goList" small outlined color="grey" class="h-lg-40px"><span class="color-grey">List</span></v-btn>
                </div>

                <!-- <div class="mt-12 mt-md-22">
                    <v-row justify="end" class="row--x-small">
                        <v-col cols="auto">
                            <v-btn outlined color="grey-d6" class="w-140px" @click="goList">목록</v-btn>
                        </v-col>
                    </v-row>
                </div> -->
            </v-container>
        </section>
        
    </client-page>
</template>

<script>
import api from "@/api";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ServiceProgramTit from "@/components/client/service/service-program-tit.vue";
import ServiceProgramCard from "@/components/client/service/service-program-card.vue";
import ServiceNavigationRow from "@/components/client/service/service-navigation-row.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        ServiceProgramCard,
        ServiceNavigationRow,
        ServiceProgramTit,
    },
    props: {
        _program: { type: String, default: null },
    },
    data() {
        return {
            program: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { program } = await api.v1.programs.get({ _id: this._program });
            this.program = program;
        },
        goList() {
            this.$router.go(-1);
        },
    },
};
</script>
