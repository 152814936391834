var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: 'tit-wrap ' + _vm.className
  }, [_vm.sc ? _c('p', {
    staticClass: "tit-txt font-weight-bold color-primary mb-4 mb-lg-18"
  }, [_c('span', {
    class: {
      'text-uppercase': _vm.type !== 'transformNone'
    }
  }, [_vm._v(" " + _vm._s(_vm.sc) + " ")])]) : _vm._e(), _c('h3', {
    staticClass: "tit font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")]), _vm.titTxt ? _c('p', {
    staticClass: "tit-txt mt-8 mt-lg-18"
  }, [_vm._v(" " + _vm._s(_vm.titTxt) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }