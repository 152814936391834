<template>
    <div :class="'tit-wrap ' + className">
        <p class="tit-txt font-weight-bold color-primary mb-4 mb-lg-18" v-if="sc">
            <span :class="{'text-uppercase': type !=='transformNone'}">
                {{sc}}
            </span>
        </p>
        <h3 class="tit font-weight-bold">
            {{tit}}
        </h3>
        <p class="tit-txt mt-8 mt-lg-18" v-if="titTxt">
            {{titTxt}}
        </p>
    </div>
</template>

<script>

export default{
    props : {
        className : {type :String, default : ""},
        sc : {type :String, default : ""},
        tit : {type :String, default : ""},
        titTxt : {type :String, default : ""},
        type : {type :String, default : ""},
    },
	components: {

	},
    data: () => {
        return {
        }
    },
	created() {
	},
	// mounted() {
	// 	this.init();
	// },
	// methods : {
	// 	init : function() {
	// 	}
	// },
}
</script>