<template>
    <div class="tab-list--primary-wrap">
        <ul class="tab-list tab-list--primary">
            <li v-for="{ to, alternate, text } in items" :key="text" :class="{ on: $route.path.includes(to) || $route.path.includes(alternate) }">
                <router-link class="button" :to="to">
                    <span>{{ text }}</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        items: { type: Array, default: () => [{ to: "/", text: "Home" }] },
    },
};
</script>

<style scoped>
</style>