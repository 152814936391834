<template>
    <input-table :loading="!program" elevation="0" class="board-table--input--sm">
        <v-row no-gutters class="ma-0" align="start">
            <v-col cols="12" xl="auto" class="d-flex align-center justify-center mb-30 mb-xl-0">
                <v-img max-width="320" class="image--full-height" :src="program?.thumb?.src">
                    <template #placeholder>
                        <v-overlay absolute opacity="0.1">
                            <v-icon x-large>mdi-image-off</v-icon>
                        </v-overlay>
                    </template>
                </v-img>
            </v-col>
            <!-- <v-col cols="12" md="auto"> <v-divider vertical class="d-none d-md-block" /> <v-divider vertical class="d-block d-md-none" /> </v-col> -->
            <v-col>
                <v-divider class="grey-13" />
                <v-row no-gutters class="ma-0">
                    <v-col cols="12" md="6">
                        <input-row label="교육명"> {{ program?.name }}</input-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <input-row label="담당자"> {{ program?.worker }}</input-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <input-row label="접수기간"> {{ program?.applicationPeriod }}</input-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <input-row label="진행기간"> {{ program?.period }}</input-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <input-row label="비용"> {{ program?.charge ? program?.charge?.format?.() + "원" : "무료" }}</input-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <input-row label="상태">
                            <div class="d-flex align-center">
                                <span class="d-inline-block mr-12 mr-lg-24">{{ program?.status }}</span>
                                <template v-if="!hideApplyButton">
                                    <service-program-apply v-bind="{ program }" />
                                </template>
                            </div>
                            <!-- <v-row align="center">
                                <v-col cols="auto">
                                    {{ program?.status }}
                                </v-col>
                                <template v-if="!hideApplyButton">
                                    <v-spacer />
                                    <v-col cols="auto">
                                        <service-program-apply v-bind="{ program }" />
                                    </v-col>
                                </template>
                            </v-row> -->
                        </input-row>
                    </v-col>
                    <v-col cols="6">
                        <input-row label="교육장소"> {{ program?.location }}</input-row>
                    </v-col>
                    <v-col cols="6">
                        <input-row label="정원"> {{ program?.capacity }}명</input-row>
                    </v-col>
                </v-row>
                <input-row label="접수방법"> {{ program?.applicationMethod }}</input-row>
                <input-row label="문의"> {{ program?.contact }}</input-row>
                <input-row label="첨부파일"> <file-chips v-bind="program" label outlined /> </input-row>
            </v-col>
        </v-row>
        <v-divider style="margin-top: -1px" />
    </input-table>
</template>

<script>
import InputRow from "@/components/client/dumb/input-row.vue";
import FileChips from "@/components/dumb/file-chips.vue";
import InputTable from "@/components/client/dumb/input-table.vue";
import ServiceProgramApply from "./service-program-apply.vue";

export default {
    components: {
        InputRow,
        FileChips,
        InputTable,
        ServiceProgramApply,
    },
    props: {
        program: { type: Object },
        hideApplyButton: { type: Boolean, default: false },
    },
};
</script>

<style scoped>
@media (min-width:1024px){
    .image--full-height{
        max-height: 392px;
    }
}
</style>

