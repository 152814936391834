var render = function render(){
  var _vm$program;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "visual": "제조지원·교육"
    }
  }), _c('service-navigation-row'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('service-program-tit'), _c('service-program-card', _vm._b({}, 'service-program-card', {
    program: _vm.program
  }, false)), _c('v-divider', {
    staticClass: "border-2 grey-13 mt-40 mt-lg-60"
  }), _c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-40"
  }, [_c('div', {
    staticClass: "page-text",
    domProps: {
      "innerHTML": _vm._s((_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : _vm$program.detail)
    }
  })]), _c('v-divider'), _c('div', {
    staticClass: "board-buttons d-flex justify-end mt-20 mt-lg-30"
  }, [_c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.goList
    }
  }, [_c('span', {
    staticClass: "color-grey"
  }, [_vm._v("List")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }