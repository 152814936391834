var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tit-primary', {
    attrs: {
      "sc": "Education",
      "tit": "교육지원",
      "titTxt": "전자+제조+데이터+경영의 이해도가 높은 전문인력 양성 / 전자생태계, 장비, 고객데이터 경험을 지원"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }