<template>
    <v-card elevation="0" class="mb-40 mb-lg-60">
        <div class="pa-20 pa-md-30 grey-light rounded-lg">

            <filter-row tit="검색어">
                <v-text-field placeholder="검색어를 입력하세요." v-model="query.searchValue" @keydown.enter="emit" hide-details />
            </filter-row>

            <v-row>
                <v-col cols="12" lg="6">
                    <filter-row tit="카테고리">
                        <autocomplete-program-category v-model="query._category" />
                    </filter-row>
                </v-col>
                <v-col cols="12" lg="6">
                    <filter-row tit="기간">
                        <v-row align="center" class="ma-n2 ma-lg-n4">
                            <v-col class="pa-2 pa-lg-4"> <v-text-field v-model="query.dateFrom" dense type="date" hide-details/> </v-col>
                            <v-col cols="auto" class="pa-2 pa-lg-4"> ~ </v-col>
                            <v-col class="pa-2 pa-lg-4"> <v-text-field v-model="query.dateTo" dense type="date" hide-details/> </v-col>
                        </v-row>
                    </filter-row>
                </v-col>
                <v-col cols="12" lg="6">
                    <filter-row tit="비용">
                        <v-radio-group v-model="query.isFree" row class="ma-0" hide-details>
                            <v-radio label="전체" value="전체" />
                            <v-radio label="무료" value="무료" />
                            <v-radio label="유료" value="유료" />
                        </v-radio-group>
                    </filter-row>
                </v-col>
                <v-col cols="12" lg="6">
                        <filter-row tit="상태">
                        <v-radio-group v-model="query.status" row class="ma-0" hide-details>
                            <v-radio label="전체" value="전체" />
                            <v-radio label="접수중" value="접수중" />
                            <v-radio label="진행중" value="진행중" />
                            <v-radio label="종료" value="종료" />
                        </v-radio-group>
                    </filter-row>
                </v-col>
            </v-row>

            <v-divider class="my-16 my-lg-30" />

            <div class="d-flex justify-end">
                <v-btn @click="emit" large class="w-80px w-lg-100px" color="grey-4" elevation="0"><span class="white--text">검색</span></v-btn>
            </div>
        </div>

        <!-- <v-card-text>
            <filter-row tit="검색어">
                <v-text-field v-model="query.searchValue" @keydown.enter="emit" />
            </filter-row>
            <filter-row tit="카테고리">
                <autocomplete-program-category v-model="query._category" />
            </filter-row>
            <filter-row tit="기간">
                <v-row no-gutters align="center" class="ma-0">
                    <v-col> <v-text-field v-model="query.dateFrom" dense type="date" /> </v-col>
                    <v-col cols="auto" class="px-3"> ~ </v-col>
                    <v-col> <v-text-field v-model="query.dateTo" dense type="date" /> </v-col>
                </v-row>
            </filter-row>
            <filter-row tit="비용">
                <v-radio-group v-model="query.isFree" row class="ma-0">
                    <v-radio label="전체" value="전체" />
                    <v-radio label="무료" value="무료" />
                    <v-radio label="유료" value="유료" />
                </v-radio-group>
            </filter-row>
            <filter-row tit="상태">
                <v-radio-group v-model="query.status" row class="ma-0">
                    <v-radio label="전체" value="전체" />
                    <v-radio label="접수중" value="접수중" />
                    <v-radio label="진행중" value="진행중" />
                    <v-radio label="종료" value="종료" />
                </v-radio-group>
            </filter-row>
        </v-card-text>
        <v-card-actions class="px-4">
            <v-spacer />
            <v-btn text @click="emit"> <v-icon>mdi-magnify</v-icon> 검색하기</v-btn>
        </v-card-actions> -->

    </v-card>
</template>

<script>
import FilterRow from "@/components/client/dumb/filter-row.vue";
import AutocompleteProgramCategory from "@/components/dumb/autocomplete-program-category.vue";

export default {
    components: {
        FilterRow,
        AutocompleteProgramCategory,
    },
    data: () => ({
        query: {
            searchValue: null,
            isFree: 0,
            status: "전체",
            dateTo: null,
            dateFrom: null,
            _category: null,
        },
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            let { searchValue = null, isFree, status, dateTo, dateFrom, _category } = this.$route.query;

            if (isFree) isFree = JSON.parse(isFree);

            if (isFree == undefined) isFree = "전체";
            else if (isFree == true) isFree = "무료";
            else if (isFree == false) isFree = "유료";

            if (status == undefined) status = "전체";

            this.query = { searchValue, isFree, status, dateTo, dateFrom, _category };
        },
        emit() {
            let query = { ...this.query };

            if (query.isFree == "전체") delete query.isFree;
            if (query.isFree == "무료") query.isFree = true;
            if (query.isFree == "유료") query.isFree = false;

            if (query.status == "전체") delete query.status;

            this.$router.push({ query });
        },
    },
};
</script>

<style>
</style>