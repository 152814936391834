var render = function render(){
  var _vm$program, _vm$program$thumb, _vm$program2, _vm$program3, _vm$program4, _vm$program5, _vm$program6, _vm$program7, _vm$program7$charge, _vm$program7$charge$f, _vm$program8, _vm$program9, _vm$program10, _vm$program11, _vm$program12;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('input-table', {
    staticClass: "board-table--input--sm",
    attrs: {
      "loading": !_vm.program,
      "elevation": "0"
    }
  }, [_c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "no-gutters": "",
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "d-flex align-center justify-center mb-30 mb-xl-0",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('v-img', {
    staticClass: "image--full-height",
    attrs: {
      "max-width": "320",
      "src": (_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : (_vm$program$thumb = _vm$program.thumb) === null || _vm$program$thumb === void 0 ? void 0 : _vm$program$thumb.src
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v("mdi-image-off")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('v-col', [_c('v-divider', {
    staticClass: "grey-13"
  }), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('input-row', {
    attrs: {
      "label": "교육명"
    }
  }, [_vm._v(" " + _vm._s((_vm$program2 = _vm.program) === null || _vm$program2 === void 0 ? void 0 : _vm$program2.name))])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('input-row', {
    attrs: {
      "label": "담당자"
    }
  }, [_vm._v(" " + _vm._s((_vm$program3 = _vm.program) === null || _vm$program3 === void 0 ? void 0 : _vm$program3.worker))])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('input-row', {
    attrs: {
      "label": "접수기간"
    }
  }, [_vm._v(" " + _vm._s((_vm$program4 = _vm.program) === null || _vm$program4 === void 0 ? void 0 : _vm$program4.applicationPeriod))])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('input-row', {
    attrs: {
      "label": "진행기간"
    }
  }, [_vm._v(" " + _vm._s((_vm$program5 = _vm.program) === null || _vm$program5 === void 0 ? void 0 : _vm$program5.period))])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('input-row', {
    attrs: {
      "label": "비용"
    }
  }, [_vm._v(" " + _vm._s((_vm$program6 = _vm.program) !== null && _vm$program6 !== void 0 && _vm$program6.charge ? ((_vm$program7 = _vm.program) === null || _vm$program7 === void 0 ? void 0 : (_vm$program7$charge = _vm$program7.charge) === null || _vm$program7$charge === void 0 ? void 0 : (_vm$program7$charge$f = _vm$program7$charge.format) === null || _vm$program7$charge$f === void 0 ? void 0 : _vm$program7$charge$f.call(_vm$program7$charge)) + "원" : "무료"))])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('input-row', {
    attrs: {
      "label": "상태"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "d-inline-block mr-12 mr-lg-24"
  }, [_vm._v(_vm._s((_vm$program8 = _vm.program) === null || _vm$program8 === void 0 ? void 0 : _vm$program8.status))]), !_vm.hideApplyButton ? [_c('service-program-apply', _vm._b({}, 'service-program-apply', {
    program: _vm.program
  }, false))] : _vm._e()], 2)])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input-row', {
    attrs: {
      "label": "교육장소"
    }
  }, [_vm._v(" " + _vm._s((_vm$program9 = _vm.program) === null || _vm$program9 === void 0 ? void 0 : _vm$program9.location))])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input-row', {
    attrs: {
      "label": "정원"
    }
  }, [_vm._v(" " + _vm._s((_vm$program10 = _vm.program) === null || _vm$program10 === void 0 ? void 0 : _vm$program10.capacity) + "명")])], 1)], 1), _c('input-row', {
    attrs: {
      "label": "접수방법"
    }
  }, [_vm._v(" " + _vm._s((_vm$program11 = _vm.program) === null || _vm$program11 === void 0 ? void 0 : _vm$program11.applicationMethod))]), _c('input-row', {
    attrs: {
      "label": "문의"
    }
  }, [_vm._v(" " + _vm._s((_vm$program12 = _vm.program) === null || _vm$program12 === void 0 ? void 0 : _vm$program12.contact))]), _c('input-row', {
    attrs: {
      "label": "첨부파일"
    }
  }, [_c('file-chips', _vm._b({
    attrs: {
      "label": "",
      "outlined": ""
    }
  }, 'file-chips', _vm.program, false))], 1)], 1)], 1), _c('v-divider', {
    staticStyle: {
      "margin-top": "-1px"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }