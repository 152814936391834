<template>
    <div role="row">
        <div role="columnheader">
            {{ label }}
            <span v-show="pointer" class="color-primary">*</span>
        </div>
        <div role="cell">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: { label: String, pointer: Boolean },
};
</script>

<style></style>
