<template>
    <navigation-row v-bind="{ items }" />
</template>

<script>
import NavigationRow from "../dumb/navigation-row.vue";
const items = [
    { text: "지원서비스 안내", to: "/service/about" },
    { text: "지원 신청", to: "/service/supports" },
    { text: "교육 신청", to: `/service/programs` },
];
export default {
    components: {
        NavigationRow,
    },
    data: () => ({
        items,
    }),
};
</script>

<style></style>
