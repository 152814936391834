<template>
    <v-btn v-bind="{ disabled, loading }" x-small rounded color="primary" @click="apply">{{ text }}</v-btn>
</template>

<script>
import api from "@/api";
export default {
    props: {
        program: { type: Object, default: () => ({}) },
    },
    data: () => ({
        application: null,
        loading: true,
    }),
    computed: {
        disabled() {
            return !!this.application || this.program?.status != "접수중";
        },
        text() {
            if (this.application) return "신청 완료";
            if (this.program?.status == "접수중") return "신청하기";
            return "신청 마감";
        },
        _program() {
            return this.program?._id;
        },
        _user() {
            return this.$store.state?.payload?._user;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        _program() {
            this.init();
        },
    },
    methods: {
        async init() {
            this.loading = true;
            try {
                let { _program } = this;
                if (_program) {
                    if (this._user) this.application = (await api.v1.me.programs.applications.getCurrent({ _program }))?.application;
                }
            } finally {
                this.loading = false;
            }
        },
        async apply() {
            if (this.$store.state.scope.includes("console")) {
                alert("관리자 계정으로는 사용할 수 없는 기능입니다");
                return;
            }

            if (!this._user) {
                alert("로그인 후 신청하실 수 있습니다");
                this.$router.push("/login");
                return;
            }

            this.$router.push(`${this.$route.path}/apply`);
        },
    },
};
</script>

<style></style>
