<template>
    <div class="input-primary">
        <div class="input-primary__tit">
            <p class="page-text--sm">{{tit}}</p>
        </div>
        <div class="input-primary__input">
            <slot/>
        </div>
    </div>
</template>

<script>

export default{
    props : {
        tit : {type :String, default : ""}
    },
	components: {

	},
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<style scoped>
.input-primary{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.input-primary__tit{
    width: 100%;
    margin-bottom: 4px;
}
.input-primary__input{
    width: 100%;
}

.input-primary:not(:last-child){
    margin-bottom: 12px;
}

@media (min-width:576px){
}
@media (min-width:768px){
    .input-primary__tit{
        width: 60px;
        padding-right: 10px;
    }
    .input-primary__input{
        width: calc(100% - 60px);
    }
}
@media (min-width:1024px){
    .input-primary__tit{
        width: 80px;
    }
    .input-primary__input{
        width: calc(100% - 80px);
    }

    .input-primary:not(:last-child){
        margin-bottom: 20px;
    }
}
@media (min-width:1200px){
}

</style>