var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "board-table board-table--input",
    attrs: {
      "role": "table"
    }
  }, 'v-card', _vm.$attrs, false), [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }