<template>
    <v-data-table class="table--primary--large" v-bind="{ headers, items }" hide-default-footer disable-filtering disable-pagination disable-sort :items-per-page="-1" @click:row="toView">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #[`item.periods`]="{ item }"> {{ item.applicationPeriod }}<br />{{ item.period }} </template>
        <template #[`item.status`]="{ item }">
            <v-chip v-if="item.status == '접수종료'" color="grey-light" label><span class="grey-dark--text">{{ item.status }}</span></v-chip>
            <v-chip v-else color="primary-light" label><span class="primary--text">{{ item.status }}</span></v-chip>
        </template>
    </v-data-table>
</template>

<script>
let headers = [
    { align: "center", text: "카테고리1", value: "category.name" },
    { align: "center", text: "교육명", value: "name" },
    { align: "center", text: "접수기간\n진행기간", value: "periods", class: "white-space-pre-line" },
    { align: "center", text: "교육장소", value: "location" },
    { align: "center", text: "수강료", value: "charge", formatter: (value) => (value ? value?.format?.() + "원" : "무료") },
    { align: "center", text: "정원", value: "capacity" },
    { align: "center", text: "상태", value: "status" },
];

export default {
    components: {},
    props: {
        programs: { type: Array, default: () => [] },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return [...this.programs];
        },
    },
    methods: {
        toView({ _id }) {
            this.$router.push({ path: `${this.$route.path}/${_id}` });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }

    tbody td {
        cursor: pointer !important;
    }
}
</style>