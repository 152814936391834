var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "visual": "제조지원·교육"
    }
  }), _c('service-navigation-row'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('service-program-tit'), _c('service-program-filter', {
    staticClass: "mt-60"
  }), _c('service-program-table', _vm._b({
    staticClass: "mt-60"
  }, 'service-program-table', {
    programs: _vm.programs
  }, false)), _c('v-pagination', {
    staticClass: "mt-60 mb-60",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 20
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }