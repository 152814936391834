<template>
    <v-card role="table" class="board-table board-table--input" v-bind="$attrs">
        <div role="rowgroup">
            <slot />
        </div>
    </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>