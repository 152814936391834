<template>
    <tit-primary
    sc="Education"
    tit="교육지원"
    titTxt="전자+제조+데이터+경영의 이해도가 높은 전문인력 양성 / 전자생태계, 장비, 고객데이터 경험을 지원">
    </tit-primary>
</template>

<script>
import TitPrimary from "@/components/client/dumb/tit-primary.vue";

export default{
    props : {
    },
	components: {
        TitPrimary,
	},
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<style scoped>
</style>