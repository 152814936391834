var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "table--primary--large",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "items-per-page": -1
    },
    on: {
      "click:row": _vm.toView
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.periods`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.applicationPeriod)), _c('br'), _vm._v(_vm._s(item.period) + " ")];
      }
    }, {
      key: `item.status`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.status == '접수종료' ? _c('v-chip', {
          attrs: {
            "color": "grey-light",
            "label": ""
          }
        }, [_c('span', {
          staticClass: "grey-dark--text"
        }, [_vm._v(_vm._s(item.status))])]) : _c('v-chip', {
          attrs: {
            "color": "primary-light",
            "label": ""
          }
        }, [_c('span', {
          staticClass: "primary--text"
        }, [_vm._v(_vm._s(item.status))])])];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }