var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-head"
  }, [_c('div', {
    staticClass: "sub-visual"
  }, [_c('v-container', {
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('div', {
    staticClass: "sub-visual__title",
    attrs: {
      "data-aos": "fade-right"
    }
  }, [_c('div', [_c('h2', {
    staticClass: "tit tit--xl line-height-12 pointed-title"
  }, [_vm._v(" " + _vm._s(_vm.sh) + " ")]), _c('p', {
    staticClass: "mt-12 mt-md-16 page-text page-text--lg"
  }, [_vm._v("금산군에서 나의 자리를 찾다. 금산군 농촌신활력플러스사업을 소개합니다.")])])])]), _c('v-img', {
    staticClass: "d-md-none",
    attrs: {
      "src": `${_vm.image}`
    }
  }), _c('div', {
    staticClass: "sub-visual__image d-none d-md-block",
    style: `background-image: url(${_vm.image});`
  })], 1), _c('client-gnb', {
    attrs: {
      "className": "sub-tab",
      "tabActive": _vm.tabActive,
      "sh": _vm.sh,
      "id": "sub-tab"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }