<template>
    <client-page class="wrap--sub">
        <sub-visual visual="제조지원·교육"></sub-visual>
        <service-navigation-row />

        <section class="section">
            <v-container>
                <service-program-tit/>
                <service-program-filter class="mt-60" />
                <service-program-table v-bind="{ programs }" class="mt-60" />
                <v-pagination :value="page" :length="pageCount" :total-visible="20" class="mt-60 mb-60" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </v-container>
        </section>
        
    </client-page>
</template>

<script>
import api from "@/api";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ServiceProgramTit from "@/components/client/service/service-program-tit.vue";
import ServiceProgramTable from "@/components/client/service/service-program-table.vue";
import ServiceProgramFilter from "@/components/client/service/service-program-filter.vue";
import ServiceNavigationRow from "@/components/client/service/service-navigation-row.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        ServiceProgramTable,
        ServiceProgramFilter,
        ServiceNavigationRow,
        ServiceProgramTit,
    },
    data() {
        return {
            programs: [],

            limit: 10,
            summary: { totalCount: 0 },
        };
    },
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        headers() {
            let { limit } = this;
            return { skip: (this.page - 1) * limit, limit };
        },
        params() {
            let { query } = this.$route;
            return { ...query, searchKey: "name" };
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async search() {
            let { headers, params } = this;
            let { summary, programs } = await api.v1.programs.gets({ headers, params });
            this.summary = summary;
            this.programs = programs;
        },
    },
};
</script>
